<template>
    <!-- CONTENT ================================================== -->
    <div class="container-fluid">
        <div class="row justify-content-center py-5">
            <div class="col-12 col-md-10 col-xl-7 py-5">
                <div class="card shadow-sm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-body py-5">
                                <!-- Heading -->
                                <h1 class="display-4 text-center mb-3">
                                    <img style="width:20%" src="https://sshsbu.e-planningbmd.id/assets/images/bandung.png">
                                </h1>
                                <!-- Subheading -->
                                <h5 class="text-muted text-center">
                                    Sistem Informasi Persediaan
                                </h5>
                                <hr>
                                <h6 class="text-muted text-center">
                                    Fitur Masih dalam Pengerjaan !!!
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";
    import { mapState } from 'vuex'

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Login'
        },
        mounted() {
            this.$store.dispatch('loadSchoolInfo');
        },
        computed: mapState(['school_info']),
        data() {
            return {
                username: "",
                password: "",
                submitted: false
            }
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        methods: {
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form login',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'admin/auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.status === "success") {
                                const getUser = response.data.user;
                                this.$router.push('/admin/dashboard');
                                if (getUser.require_twofactor == '1') {
                                    localStorage.setItem('name', getUser.fullname);
                                    localStorage.setItem('email', getUser.email);
                                    this.$router.push('/admin/twofactor');
                                } else {
                                    const token = getUser.key;
                                    localStorage.setItem('auth_token', token);
                                    axios.defaults.headers.common['Authorization'] = token;
                                    this.$router.push('/admin/dashboard');
                                }
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                            console.debug("Login issue: " + error)
                        });
                }
            },
        }
    }
</script>